import React, { useState, useEffect } from "react";

const Admin = () => {
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(true);
  const [attemptCount, setAttemptCount] = useState(0);
  const [lockoutTime, setLockoutTime] = useState(0);

  const restaurantPass = process.env.REACT_APP_ADMIN_KEY;

  useEffect(() => {
    const passwordTimestamp = localStorage.getItem("passwordTimestamp");
    const savedAttemptCount = localStorage.getItem("attemptCount");
    const savedLockoutTime = localStorage.getItem("lockoutTime");

    if (savedAttemptCount) {
      setAttemptCount(parseInt(savedAttemptCount));
    }

    if (savedLockoutTime) {
      const currentTime = new Date().getTime();
      const remainingLockout = parseInt(savedLockoutTime) - currentTime;

      if (remainingLockout > 0) {
        setLockoutTime(remainingLockout);
        setTimeout(() => {
          setLockoutTime(0);
          localStorage.removeItem("lockoutTime");
        }, remainingLockout);
      }
    }

    if (passwordTimestamp) {
      const currentTime = new Date().getTime();
      const passwordTimeout = 17 * 60 * 60 * 1000; // 17 hours
      if (currentTime - passwordTimestamp < passwordTimeout) {
        setPasswordRequired(false);
        setLoggedIn(true);
        window.location.href = "/21232f297a57a5a743894a0e4a801fc3/management"; // Süre dolmadıysa direkt yönlendirme
      }
    }
  }, []);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (lockoutTime > 0) {
      alert("Çok fazla yanlış deneme yaptınız. Lütfen tekrar deneyin.");
      return;
    }

    if (password === restaurantPass) {
      setLoggedIn(true);
      localStorage.setItem(
        "passwordTimestamp",
        new Date().getTime().toString()
      );
      localStorage.removeItem("attemptCount");
      setAttemptCount(0);
      window.location.href = "/21232f297a57a5a743894a0e4a801fc3/management"; // Şifre doğruysa yönlendirme
    } else {
      const newAttemptCount = attemptCount + 1;
      setAttemptCount(newAttemptCount);
      localStorage.setItem("attemptCount", newAttemptCount);

      const newLockoutTime = new Date().getTime() + newAttemptCount * 30000; // Her yanlış denemede 30 saniye artan bekleme süresi
      localStorage.setItem("lockoutTime", newLockoutTime.toString());

      setLockoutTime(newAttemptCount * 10000);
      setTimeout(() => {
        setLockoutTime(0);
        localStorage.removeItem("lockoutTime");
      }, newAttemptCount * 10000);

      alert("Hatalı şifre. Lütfen tekrar deneyin.");
      setPassword("");
    }
  };

  return (
    <div className="Admin bg-gray-200 min-h-screen flex items-center justify-center">
      {loggedIn ? (
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">Giriş Başarılı!</h1>
          {/* Burada kullanıcı giriş yaptıktan sonra göstermek istediğiniz içeriği ekleyebilirsiniz. */}
        </div>
      ) : (
        passwordRequired && (
          <form
            onSubmit={handleSubmit}
            className="bg-white p-8 rounded-lg shadow-md"
          >
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-gray-600 font-semibold"
              >
                Şifre:
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                disabled={lockoutTime > 0}
              />
            </div>
            {lockoutTime > 0 && (
              <div className="mb-4 text-red-600">
                Lütfen {Math.ceil(lockoutTime / 1000)} saniye bekleyin.
              </div>
            )}
            <button
              type="submit"
              className={`bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-300 ${
                lockoutTime > 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={lockoutTime > 0}
            >
              Giriş Yap
            </button>
          </form>
        )
      )}
    </div>
  );
};

export default Admin;
