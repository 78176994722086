import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element }) => {
  const passwordTimestamp = localStorage.getItem("passwordTimestamp");
  const passwordTimeout = 17 * 60 * 60 * 1000; // 17 hours

  if (passwordTimestamp) {
    const currentTime = new Date().getTime();
    if (currentTime - passwordTimestamp < passwordTimeout) {
      return element; // Kullanıcı giriş yapmışsa bileşeni render et
    }
  }

  return <Navigate to="/21232f297a57a5a743894a0e4a801fc3" replace />; // Giriş yapmamışsa yönlendir
};

export default PrivateRoute;
