import React, { useState, useEffect } from "react";
import AdminHeader from "./adminHeader";
import supabase from "../../config/supabaseClient";

const CategorySettings = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryName, setCategoryName] = useState("");

  /*
  useEffect(() => {
    const savedCategories =
      JSON.parse(localStorage.getItem("categories")) || [];
    setCategories(savedCategories.sort((a, b) => a.cat_name.localeCompare(b.cat_name)));
  }, []); */

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase.from("categories").select();

      if (data) {
        setCategories(data);

        return;
      }

      if (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCategoryName(category.cat_name);
  };

  const handleNameChange = (e) => {
    //console.log("değişti");
    setCategoryName(e.target.value);
  };

  const handleSave = async () => {
    //console.log("geldik");
    if (categoryName.trim() === "") return;

    if (categories.some((cat) => cat.cat_name === categoryName.trim())) {
      alert("Aynı isimde bir kategori zaten mevcut.");
      return;
    }

    if (selectedCategory.cat_name === "Yeni Kategori") {
      const { data, error } = await supabase
        .from("categories")
        .insert({ cat_name: categoryName })
        .select();
      if (error) {
        console.log(error);
        return;
      } else {
        const updatedCategories = categories.map((category) =>
          category.id === selectedCategory.id
            ? { ...category, cat_name: categoryName }
            : category
        );
        setCategories(
          updatedCategories.sort((a, b) => a.cat_name.localeCompare(b.cat_name))
        );
        //localStorage.setItem("categories", JSON.stringify(updatedCategories));
        setSelectedCategory({ ...selectedCategory, name: categoryName });
        //burası
        setSelectedCategory(null);
      }
    } else if (selectedCategory.cat_name !== "Yeni Kategori") {
      //console.log("merhaba ", selectedCategory.cat_name);
      const { data, error } = await supabase
        .from("categories")
        .update({ cat_name: categoryName })
        .eq("cat_name", selectedCategory.cat_name)
        .select();
      if (error) {
        console.log(error);
        return;
      } else if (data) {
        //console.log("data: ", data);
        const updatedCategories = categories.map((category) =>
          category.id === selectedCategory.id
            ? { ...category, cat_name: categoryName }
            : category
        );
        setCategories(
          updatedCategories.sort((a, b) => a.cat_name.localeCompare(b.cat_name))
        );
        //localStorage.setItem("categories", JSON.stringify(updatedCategories));
        setSelectedCategory(null);

        return;
      }
    }
  };

  const handleDelete = async () => {
    const updatedCategories = categories.filter(
      (category) => category.id !== selectedCategory.id
    );

    const { data, error } = await supabase
      .from("categories")
      .delete()
      .eq("cat_name", categoryName)
      .select();

    if (error) {
      console.log("hata: ", error);
      return;
    }

    if (data) {
      setCategories(
        updatedCategories.sort((a, b) => a.cat_name.localeCompare(b.cat_name))
      );
      //localStorage.setItem("categories", JSON.stringify(updatedCategories));
      setSelectedCategory(null);
      setCategoryName("");
      return;
    }
  };

  const handleAddCategory = () => {
    if (categories.some((cat) => cat.cat_name === "Yeni Kategori")) return;

    const newCategory = {
      id: Date.now(),
      cat_name: "Yeni Kategori",
    };
    const updatedCategories = [...categories, newCategory];
    setCategories(
      updatedCategories.sort((a, b) => a.cat_name.localeCompare(b.cat_name))
    );
    handleCategoryClick(newCategory);
    //localStorage.setItem("categories", JSON.stringify(updatedCategories));
  };

  return (
    <div className="CategorySettings">
      <AdminHeader />
      <div className="flex h-screen">
        {/* Sol kısım - Mevcut kategoriler */}
        <div className="w-2/3 p-4 bg-gray-100">
          <h2 className="text-2xl font-semibold mb-4">Mevcut Kategoriler</h2>

          <div className="grid grid-cols-5 gap-4">
            {categories.map((category) => (
              <div
                key={category.id}
                className={`p-4 bg-white rounded cursor-pointer ${
                  selectedCategory?.id === category.id ? "bg-blue-200" : ""
                }`}
                onClick={() => handleCategoryClick(category)}
              >
                {category.cat_name}
              </div>
            ))}
          </div>
          <button
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
            onClick={handleAddCategory}
          >
            Kategori Ekle +
          </button>
        </div>

        {/* Sağ kısım - Seçilen kategori ayarları */}
        <div className="w-1/3 p-4 bg-white border-l">
          {selectedCategory ? (
            <>
              <h2 className="text-2xl font-semibold mb-4">Kategori Ayarları</h2>
              <div className="mb-4">
                <label className="block text-gray-700">Kategori İsmi</label>
                <input
                  type="text"
                  value={categoryName}
                  onChange={handleNameChange}
                  className="w-full p-2 mt-2 border rounded"
                />
              </div>
              <button
                onClick={handleSave}
                className={`mb-4 mr-4 px-4 py-2 ${
                  categoryName.trim() &&
                  !categories.some(
                    (cat) => cat.cat_name === categoryName.trim()
                  )
                    ? "bg-green-500"
                    : "bg-gray-400"
                } text-white rounded`}
                disabled={
                  !categoryName.trim() ||
                  categories.some((cat) => cat.cat_name === categoryName.trim())
                }
              >
                Kaydet
              </button>
              <button
                onClick={handleDelete}
                className=" px-4 py-2 bg-red-500 text-white rounded"
              >
                Kategoriyi Sil
              </button>
            </>
          ) : (
            <p className=" text-gray-700 flex justify-center items-center h-full">
              Bir kategori seçin.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategorySettings;
