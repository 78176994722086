import React, { useState, useEffect } from "react";
import AdminHeader from "./adminHeader";
import QRCode from "qrcode.react";
import supabase from "../../config/supabaseClient";

const TableSettings = () => {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [tableName, setTableName] = useState("");

  const websiteUrl = "http://twincoffee.com.tr/"; // URL
  const localUrl = "http://localhost:3000/"; // URL

  /*
  useEffect(() => {
    // //localstorage'dan mevcut masaları çekme ve alfabetik sıraya dizme
    const savedTables = JSON.parse(//localstorage.getItem("tables")) || [];
    setTables(savedTables.sort((a, b) => a.table_name.localeCompare(b.table_name)));
  }, []);

  */

  useEffect(() => {
    const fetchTables = async () => {
      const { data, error } = await supabase.from("tables").select("*");
      if (error) console.log("error", error);
      else setTables(data);
    };
    fetchTables();
  }, []);

  const handleTableClick = (table) => {
    setSelectedTable(table);
    setTableName(table.table_name);
  };

  const handleNameChange = (e) => {
    setTableName(e.target.value);
  };

  const handleSave = async () => {
    if (tableName.trim() === "") return;

    // Aynı isimde masa var mı kontrolü
    if (
      tables.some(
        (table) =>
          table.table_name === tableName && table.id !== selectedTable.id
      )
    ) {
      alert("Aynı isimde bir masa zaten mevcut.");
      return;
    }

    if (selectedTable.table_name === "Yeni Masa") {
      const { data, error } = await supabase
        .from("tables")
        .insert([{ table_name: tableName }])
        .select();
      if (error) console.log("error", error);
      else {
        console.log("data", data);
        const updatedTables = tables.map((table) =>
          table.id === selectedTable.id
            ? { ...table, table_name: tableName }
            : table
        );
        setTables(
          updatedTables.sort((a, b) => a.table_name.localeCompare(b.table_name))
        );
        ////localstorage.setItem("tables", JSON.stringify(updatedTables));
        setSelectedTable({ ...selectedTable, table_name: tableName });
      }
    } else if (selectedTable.table_name !== "Yeni Masa") {
      const { data, error } = await supabase
        .from("tables")
        .update({ table_name: tableName })
        .eq("table_name", selectedTable.table_name)
        .select();

      if (error) return console.log("error", error);
      else if (data) {
        const updatedTables = tables.map((table) =>
          table.id === selectedTable.id
            ? { ...table, table_name: tableName }
            : table
        );
        setTables(
          updatedTables.sort((a, b) => a.table_name.localeCompare(b.table_name))
        );
        ////localstorage.setItem("tables", JSON.stringify(updatedTables));
        setSelectedTable({ ...selectedTable, table_name: tableName });
      }
    }
    setSelectedTable(null);
  };

  const handleDelete = async () => {
    const { data, error } = await supabase
      .from("tables")
      .delete()
      .eq("table_name", selectedTable.table_name)
      .select();

    if (error) return console.log("error", error);
    else {
      const updatedTables = tables.filter(
        (table) => table.id !== selectedTable.id
      );
      setTables(
        updatedTables.sort((a, b) => a.table_name.localeCompare(b.table_name))
      );
      //localstorage.setItem("tables", JSON.stringify(updatedTables));
      setSelectedTable(null);
      setTableName("");
    }
  };

  const handleAddTable = () => {
    // Aynı isimde masa var mı kontrolü
    if (tables.some((table) => table.table_name === "Yeni Masa")) {
      alert("Aynı isimde bir masa zaten mevcut.");
      return;
    }

    const newTable = {
      id: Date.now(),
      table_name: "Yeni Masa",
    };
    const updatedTables = [...tables, newTable];
    setTables(
      updatedTables.sort((a, b) => a.table_name.localeCompare(b.table_name))
    );
    handleTableClick(newTable);
    //setSelectedTable(newTable);

    //localstorage.setItem("tables", JSON.stringify(updatedTables));
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${tableName}_QRCode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="TableSettings">
      <AdminHeader />
      <div className="flex h-screen">
        {/* Sol kısım - Mevcut masalar */}
        <div className="w-2/3 p-4 bg-gray-100">
          <h2 className="text-2xl font-semibold mb-4">Mevcut Masalar</h2>

          <div className="grid grid-cols-5 gap-4">
            {tables.map((table) => (
              <div
                key={table.id}
                className={`p-4 bg-white rounded cursor-pointer ${
                  selectedTable?.id === table.id ? "bg-blue-200" : ""
                }`}
                onClick={() => handleTableClick(table)}
              >
                {table.table_name}
              </div>
            ))}
          </div>
          <button
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
            onClick={handleAddTable}
          >
            Masa Ekle +
          </button>
        </div>

        {/* Sağ kısım - Seçilen masa ayarları */}
        <div className="w-1/3 p-4 bg-white border-l">
          {selectedTable ? (
            <>
              <h2 className="text-2xl font-semibold mb-4">Masa Ayarları</h2>
              <div className="mb-4">
                <label className="block text-gray-700">Masa İsmi</label>
                <input
                  type="text"
                  value={tableName}
                  onChange={handleNameChange}
                  className="w-full p-2 mt-2 border rounded"
                />
              </div>
              <button
                onClick={handleSave}
                className={`mb-4 px-4 py-2 ${
                  tableName.trim() ? "bg-green-500" : "bg-gray-400"
                } text-white rounded`}
                disabled={!tableName.trim()}
              >
                Kaydet
              </button>
              <div className="mb-4">
                <label className="block text-gray-700">QR Kodu</label>
                <QRCode
                  id="qr-code"
                  //value={`${websiteUrl}${tableName}`}
                  value={`${websiteUrl}${tableName}`}
                  size={128}
                  level={"H"}
                />
                <button
                  onClick={downloadQRCode}
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
                >
                  QR Kodunu İndir
                </button>
              </div>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Masayı Sil
              </button>
            </>
          ) : (
            <p className="text-gray-700 flex justify-center items-center h-full">
              Bir masa seçin.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableSettings;
