import React, { useState, useEffect } from "react";
import AdminHeader from "./adminHeader";
import supabase from "../../config/supabaseClient";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const History = () => {
  const [historyData, setHistoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [dateRange, setDateRange] = useState({ start: "", end: "" });

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase.from("orderHistory").select();

      if (data) {
        setHistoryData(data);
        setFilteredData(data);
      }

      if (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setSortConfig({ key, direction });
    setFilteredData(sortedData);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearch = () => {
    let filtered = historyData;

    if (dateRange.start && dateRange.end) {
      const startDate = new Date(dateRange.start);
      const endDate = new Date(dateRange.end);

      // Bitiş tarihinin saatini 23:59:59 olarak ayarla
      endDate.setHours(23, 59, 59, 999);

      filtered = filtered.filter((item) => {
        const createdAt = new Date(item.created_at);
        return createdAt >= startDate && createdAt <= endDate;
      });
    }

    setFilteredData(filtered);
  };

  const handleReset = () => {
    setFilteredData(historyData);
    setDateRange({ start: "", end: "" });
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "History");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "history_data.xlsx");
  };

  return (
    <div className="History bg-gray-100 h-screen ">
      <AdminHeader />
      <div className="flex justify-between p-4">
        <div className="flex space-x-4">
          <div>
            <label htmlFor="start-date" className="block text-gray-700">
              Başlangıç Tarihi:
            </label>
            <input
              type="date"
              name="start"
              value={dateRange.start}
              onChange={handleDateChange}
              className="p-2 border rounded"
            />
          </div>
          <div>
            <label htmlFor="end-date" className="block text-gray-700">
              Bitiş Tarihi:
            </label>
            <input
              type="date"
              name="end"
              value={dateRange.end}
              onChange={handleDateChange}
              className="p-2 border rounded"
            />
          </div>
          <button
            onClick={handleSearch}
            className="p-2 bg-blue-500 text-white rounded"
          >
            Ara
          </button>
          <button
            onClick={handleReset}
            className="p-2 bg-gray-500 text-white rounded"
          >
            Sıfırla
          </button>
        </div>
        <button
          onClick={exportToExcel}
          className="p-2 bg-green-500 text-white rounded"
        >
          Excel'e Aktar
        </button>
      </div>

      <div className="p-4  bg-gray-100">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th
                className="py-2 px-4 border-b cursor-pointer text-left"
                onClick={() => handleSort("id")}
              >
                ID{" "}
                {sortConfig.key === "id" &&
                  (sortConfig.direction === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="py-2 px-4 border-b cursor-pointer text-left"
                onClick={() => handleSort("created_at")}
              >
                Tarih{" "}
                {sortConfig.key === "created_at" &&
                  (sortConfig.direction === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="py-2 px-4 border-b cursor-pointer text-left"
                onClick={() => handleSort("table_id")}
              >
                Masa ID{" "}
                {sortConfig.key === "table_id" &&
                  (sortConfig.direction === "asc" ? "↑" : "↓")}
              </th>
              <th className="py-2 px-4 border-b text-left">Sipariş</th>
              <th className="py-2 px-4 border-b text-left">Ödeme Yöntemi</th>
              <th
                className="py-2 px-4 border-b cursor-pointer text-left"
                onClick={() => handleSort("totalPrice")}
              >
                Toplam Fiyat{" "}
                {sortConfig.key === "totalPrice" &&
                  (sortConfig.direction === "asc" ? "↑" : "↓")}
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item.id}>
                <td className="py-2 px-4 border-b">{item.id}</td>
                <td className="py-2 px-4 border-b">
                  {new Date(item.created_at).toLocaleString()}
                </td>
                <td className="py-2 px-4 border-b">{item.table_id}</td>
                <td className="py-2 px-4 border-b">
                  {item.totalOrder.map((order) => {
                    return (
                      <div key={order.id}>
                        {order.name} x {order.count}
                      </div>
                    );
                  })}
                </td>
                <td className="py-2 px-4 border-b">{item.paymentMethod}</td>
                <td className="py-2 px-4 border-b">{item.totalPrice}₺</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default History;
