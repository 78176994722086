import React, { useState, useEffect } from "react";
import AdminHeader from "./adminHeader";
import supabase from "../../config/supabaseClient";

const MenuSettings = () => {
  const [menus, setMenus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [menuData, setMenuData] = useState({
    item_name: "",
    imageUrl: "",
    description: "",
    category: "",
    price: "",
    stock: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  /*
  useEffect(() => {
    const savedMenus = JSON.parse(//localStorage.getItem("menus")) || [];
    const savedCategories =
      JSON.parse(//localStorage.getItem("categories")) || [];
    setMenus(savedMenus.sort((a, b) => a.item_name.localeCompare(b.item_name)));
    setCategories(savedCategories.sort((a, b) => a.item_name.localeCompare(b.item_name)));
  }, []); */

  useEffect(() => {
    const fetchItems = async () => {
      const { data, error } = await supabase.from("items").select("*");
      if (error) console.log("error", error);
      else {
        setMenus(data);
      }
    };

    const fetchCategories = async () => {
      const { data, error } = await supabase.from("categories").select("*");
      if (error) console.log("error", error);
      else {
        setCategories(data);
      }
    };

    fetchCategories();
    fetchItems();
  }, []);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    setMenuData({
      item_name: menu.item_name,
      imageUrl: menu.imageUrl,
      description: menu.description,
      category: menu.category,
      price: menu.price,
      stock: menu.stock,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMenuData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCategory = (e) => {
    const { name, value } = e.target;
    setMenuData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    if (
      menuData.item_name.trim() === "" ||
      menuData.price === "" ||
      menuData.category === "-1"
    )
      return;

    const isDuplicateName = menus.some(
      (menu) =>
        menu.item_name === menuData.item_name && menu.id !== selectedMenu.id
    );

    if (isDuplicateName) {
      alert("Aynı isimde bir ürün zaten mevcut.");
      return;
    }

    if (selectedMenu.item_name === "Yeni Ürün") {
      const { data, error } = await supabase
        .from("items")
        .insert([menuData])
        .select()
        .single();

      if (error) return alert("Hata", error);
      else {
        const updatedMenus = menus.map((menu) =>
          menu.id === selectedMenu.id ? { ...menu, ...menuData } : menu
        );
        setMenus(
          updatedMenus.sort((a, b) => a.item_name.localeCompare(b.item_name))
        );
        //localStorage.setItem("menus", JSON.stringify(updatedMenus));
        setSelectedMenu({ ...selectedMenu, ...menuData });
      }
      return;
    } else if (selectedMenu.item_name !== "Yeni Ürün") {
      const { data, error } = await supabase
        .from("items")
        .update(menuData)
        .eq("item_name", selectedMenu.item_name)
        .select()
        .single();

      if (error) return alert("Hata", error);
      else {
        const updatedMenus = menus.map((menu) =>
          menu.id === selectedMenu.id ? { ...menu, ...menuData } : menu
        );
        setMenus(
          updatedMenus.sort((a, b) => a.item_name.localeCompare(b.item_name))
        );
        //localStorage.setItem("menus", JSON.stringify(updatedMenus));
        setSelectedMenu({ ...selectedMenu, ...menuData });
      }
    }
  };

  const handleDelete = async () => {
    const { data, error } = await supabase
      .from("items")
      .delete()
      .eq("item_name", selectedMenu.item_name)
      .select();

    if (error) return console.log("error", error);
    else {
      const updatedMenus = menus.filter((menu) => menu.id !== selectedMenu.id);
      setMenus(
        updatedMenus.sort((a, b) => a.item_name.localeCompare(b.item_name))
      );
      //localStorage.setItem("menus", JSON.stringify(updatedMenus));
      setSelectedMenu(null);
      setMenuData({
        item_name: "",
        imageUrl: "",
        description: "",
        category: "",
        price: "",
        stock: "",
      });
    }
  };

  const handleAddMenu = () => {
    const isDuplicateName = menus.some(
      (menu) => menu.item_name === "Yeni Ürün"
    );

    if (isDuplicateName) {
      alert("Aynı isimde bir ürün zaten mevcut.");
      return;
    }

    const newMenu = {
      id: Math.random(),
      item_name: "Yeni Ürün",
      imageUrl: "",
      description: "",
      category: "",
      price: "",
      stock: "",
    };
    const updatedMenus = [...menus, newMenu];
    setMenus(
      updatedMenus.sort((a, b) => a.item_name.localeCompare(b.item_name))
    );
    handleMenuClick(newMenu);
    //localStorage.setItem("menus", JSON.stringify(updatedMenus));
  };

  return (
    <div className="MenuSettings">
      <AdminHeader />
      <div className="flex w-screen ">
        {/* Sol kısım - Mevcut ürünler */}
        <div className="w-2/3 p-4 bg-gray-100">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-semibold">Mevcut Ürünler</h2>
            <div className="flex">
              <input
                type="text"
                placeholder="Ürün ara..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="p-2 border rounded mr-2"
              />
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded"
                onClick={handleAddMenu}
              >
                Ürün Ekle
              </button>
            </div>
          </div>

          <div className="grid grid-cols-5 gap-4">
            {menus
              .filter((menu) =>
                menu.item_name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((menu) => (
                <div
                  key={menu.id}
                  className={`p-4 bg-white rounded cursor-pointer ${
                    selectedMenu?.id === menu.id ? "bg-blue-200" : ""
                  }`}
                  onClick={() => handleMenuClick(menu)}
                >
                  {menu.item_name}
                </div>
              ))}
          </div>
        </div>

        {/* Sağ kısım - Seçilen ürün ayarları */}
        <div className="w-1/3 p-4  bg-white border-l">
          {selectedMenu ? (
            <>
              <h2 className="text-2xl font-semibold mb-4">Ürün Ayarları</h2>
              <div className="flex items-start mb-4">
                <div className="w-1/3">
                  <img
                    src={menuData.imageUrl || "https://via.placeholder.com/100"}
                    alt="Menu"
                    className="w-full h-auto rounded"
                  />
                </div>
                <div className="w-2/3 ml-4">
                  <label className="block text-gray-700">Ürün İsmi</label>
                  <input
                    type="text"
                    name="item_name"
                    value={menuData.item_name}
                    onChange={handleInputChange}
                    className="w-full p-2 mt-2 border rounded mb-4"
                  />
                  <label className="block text-gray-700">Resim Linki</label>
                  <input
                    type="text"
                    name="imageUrl"
                    value={menuData.imageUrl}
                    onChange={handleInputChange}
                    className="w-full p-2 mt-2 border rounded mb-4"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Açıklama</label>
                <textarea
                  name="description"
                  value={menuData.description}
                  onChange={handleInputChange}
                  className="w-full p-2 mt-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Kategori</label>
                <select
                  name="category"
                  value={menuData.category}
                  onChange={handleCategory}
                  className="w-full p-2 mt-2 border rounded"
                >
                  <option value="-1">Kategori Seçin</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {" "}
                      {/* Burada value olarak isim yerine id alıyoruz */}
                      {category.cat_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Fiyat</label>
                <input
                  type="number"
                  name="price"
                  value={menuData.price}
                  onChange={handleInputChange}
                  className="w-full p-2 mt-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Stok</label>
                <input
                  type="number"
                  name="stock"
                  value={menuData.stock}
                  onChange={handleInputChange}
                  placeholder="Stok bilgisi girin veya boş bırakın"
                  className="w-full p-2 mt-2 border rounded"
                />
              </div>
              <button
                onClick={handleSave}
                className={`mb-4 px-4 py-2 ${
                  menuData.item_name.trim() &&
                  menuData.price &&
                  menuData.category !== "-1"
                    ? "bg-green-500"
                    : "bg-gray-400"
                } text-white rounded`}
                disabled={
                  !menuData.item_name.trim() ||
                  !menuData.price ||
                  menuData.category === "-1"
                }
              >
                Kaydet
              </button>
              <button
                onClick={handleDelete}
                className="ml-4 px-4 py-2 bg-red-500 text-white rounded"
              >
                Ürünü Sil
              </button>
            </>
          ) : (
            <p className="text-gray-700 flex justify-center h-screen items-center h-full">
              Bir ürün seçin.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuSettings;
