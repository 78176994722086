//+- adet
import classes from "./menuItem.module.css";
import ClickedItem from "./clickedItem";
import { useState } from "react";
const MenuItem = (props) => {
  const [showMeals, setShowMeals] = useState(false);
  const [thr, setThr] = useState("+");
  const [clickedItem, setClickedItem] = useState({});
  const [details, setDetails] = useState(false);

  const addClickedItem = (item) => {
    setDetails(false);
    //props.onAddItem(item);
    //console.log("buradaki item ", item);
    //console.log(item,"eklendi")
  };

  const closeClickedItem = () => {
    setDetails(false);
  };

  const displayMeals = () => {
    setShowMeals((prevShowMeals) => !prevShowMeals);
    if (!showMeals) {
      setThr("-");
    } else {
      setThr("+");
    }
  };

  const showDetails = (meal) => {
    //console.log(meal,"detaylar gösteriliyor");
    setClickedItem(meal);
    setDetails(true);
  };

  return (
    <>
      <div className={classes.menuCard}>
        {" "}
        <div className={classes.details} onClick={displayMeals}>
          <p>{props.mealType} </p>
          <p>{thr}</p>
        </div>
        {showMeals && (
          <div>
            {props.mealList.map((meal) => (
              <div
                key={Math.random()}
                className={classes.menuList}
                onClick={() => showDetails(meal)}
              >
                <div>{meal.item_name}</div> <div>{meal.price}₺</div>
              </div>
            ))}
          </div>
        )}
      </div>
      {details && (
        <ClickedItem
          name={clickedItem.item_name}
          price={clickedItem.price}
          img={clickedItem.imageUrl}
          description={clickedItem.description}
          stock={clickedItem.stock}
          category={clickedItem.category}
          onAdd={addClickedItem}
          onClose={closeClickedItem}
          id={clickedItem.id}
        />
      )}
    </>
  );
};
export default MenuItem;
