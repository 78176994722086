import React, { useState, useEffect } from "react";
import supabase from "../../config/supabaseClient";
import NotificationsIcon from "@mui/icons-material/Notifications"; // Bildirim zili ikonu

const AdminHeader = () => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      const { data, error } = await supabase.from("notification").select();

      if (error) {
        console.log("Bildirimler alınamadı:", error);
      } else {
        setNotifications(data);
      }
    };
    //her 3 sanıyede bir bildirimleri kontrol eder sleep fonksiyonu
    const interval = setInterval(() => {
      fetchNotifications();
    }, 3000);
  }, []);

  const signOut = () => {
    localStorage.removeItem("passwordTimestamp");
    window.location.href = "/21232f297a57a5a743894a0e4a801fc3";
  };

  const management = () => {
    window.location.href = "/21232f297a57a5a743894a0e4a801fc3/management";
  };

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleNotificationDismiss = async (id) => {
    const { data, error } = await supabase
      .from("notification")
      .delete()
      .eq("id", id);

    if (error) {
      console.log("Bildirim silinemedi:", error);
    } else {
      setNotifications(
        notifications.filter((notification) => notification.id !== id)
      );
    }
  };

  return (
    <nav className="bg-blue-500 p-2 pr-4 pl-4 flex justify-between items-center">
      <div className="flex items-center space-x-4">
        <img
          src="/logo.png" // Logo görüntüsünün yolunu buraya ekleyin
          alt="Logo"
          className="w-12 h-12"
          onClick={management}
        />
        <a
          href="/21232f297a57a5a743894a0e4a801fc3/tableSettings"
          className="text-white hover:underline"
        >
          Masa Ayarları
        </a>
        <a
          href="/21232f297a57a5a743894a0e4a801fc3/categorySettings"
          className="text-white hover:underline"
        >
          Kategori Ayarları
        </a>
        <a
          href="/21232f297a57a5a743894a0e4a801fc3/menuSettings"
          className="text-white hover:underline"
        >
          Ürün Ayarları
        </a>
        <a
          href="/21232f297a57a5a743894a0e4a801fc3/history"
          className="text-white hover:underline"
        >
          Geçmiş
        </a>
      </div>
      <div className="flex items-center space-x-4">
        {/* Bildirim Zili */}
        <div className="relative">
          <button className="text-white" onClick={handleNotificationClick}>
            <NotificationsIcon />
          </button>
          {notifications.length > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white w-4 h-4 rounded-full flex items-center justify-center text-xs">
              {notifications.length}
            </span>
          )}
        </div>
        {showNotifications && (
          <div className="absolute right-2 top-11 mt-2 w-64 bg-white rounded-lg shadow-lg z-10">
            <div className="flex justify-between p-2 bg-gray-100 border-b">
              <span className="font-semibold">Bildirimler</span>
              <button
                onClick={async () => {
                  const notificationIds = notifications.map(
                    (notification) => notification.id
                  );

                  const { data, error } = await supabase
                    .from("notification")
                    .delete()
                    .in("id", notificationIds); // Tüm bildirimi id'lerine göre siler

                  if (error) {
                    console.log("Bildirimler silinemedi:", error);
                  } else {
                    setNotifications([]); // Bildirimleri temizle
                  }
                }}
                className="text-red-500 hover:text-red-700"
              >
                Hepsini Temizle
              </button>
            </div>
            <ul className="divide-y divide-gray-200">
              {notifications.map((notification) => (
                <li
                  key={notification.id}
                  className="p-2 flex justify-between items-center"
                  onClick={() => {
                    if (
                      notification.message.startsWith("Yeni sipariş verildi")
                    ) {
                      management(); // "management" fonksiyonunu çağır
                    }
                  }}
                >
                  <span>{notification.message}</span>
                  <button
                    onClick={() => handleNotificationDismiss(notification.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    &times;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <button
          className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-300"
          onClick={signOut}
        >
          Çıkış Yap
        </button>
      </div>
    </nav>
  );
};

export default AdminHeader;
