import classes from "./home.module.css";
import MenuItem from "../components/userComponents/menuItem";
import TotalOrderComponent from "../components/ui/totalOrderComponent";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CurrentOrder from "../components/userComponents/CurrentOrder";
import supabase from "../config/supabaseClient";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const [orders, setOrders] = useState([]);
  const [items, setItems] = useState([]);
  const [currentOrders, setCurrentOrders] = useState();
  const [orderId, setOrderId] = useState();

  const navigate = useNavigate();

  const { table_name } = useParams();

  const [table_id, setTable_id] = useState();

  //console.log("table_name", table_name);
  useEffect(() => {
    const fecthItems = async () => {
      const { data, error } = await supabase
        .from("items")
        .select()
        .order("category", { ascending: true });

      if (error) {
        //console.log("error", error);
      } else {
        let newMenuList = [];
        let categoryMap = {};

        data.forEach((item) => {
          if (categoryMap[item.category]) {
            categoryMap[item.category].mealList.push(item);
          } else {
            const newCategory = {
              name: item.category,
              mealList: [item],
            };
            categoryMap[item.category] = newCategory;
            newMenuList.push(newCategory);
          }
        });
        ////console.log("yeni liste ik ", newMenuList);

        for (var i = 0; i < newMenuList.length; i++) {
          const { data, error } = await supabase
            .from("categories")
            .select("cat_name")
            .eq("id", newMenuList[i].name)
            .single();

          if (error) {
            //console.log(error);
            return;
          } else {
            newMenuList[i].name = data.cat_name;
          }
        }

        ////console.log("yeni liste", newMenuList);
        setItems(newMenuList);
      }
    };
    fecthItems();
    //console.log("cur", currentOrders);
  }, []);

  const makeOrder = async () => {
    let totalPrice = 0;
    for (let i = 0; i < orders.length; i++) {
      totalPrice += orders[i].price * orders[i].count;
    }

    if (totalPrice !== 0) {
      if (!currentOrders) {
        //burası yeni siparişler için
        //console.log("yeni siparişler", orders);
        const { data, error } = await supabase
          .from("activeOrder")
          .insert({
            table_id: table_id,
            totalOrder: orders,
            totalPrice: totalPrice,
          })
          .select();

        if (!data || error) {
          //console.log("Sipariş verme hatası", error);
        } else {
          const { data: tableData, error: tableError } = await supabase
            .from("tables")
            .update({ hasGuest: true })
            .eq("id", table_id)
            .select();
          if (tableError) {
            //console.log("Masa güncelleme hatası", tableError);
          } else {
            //console.log(tableData);
          }
        }
      } else {
        //burası  zaten var olan siparişler için

        const existingOrders = { ...currentOrders };
        const newOrders = [...orders];
        const existingPrice = currentOrders.totalPrice;

        let combinedOrders = [...currentOrders.totalOrder];

        orders.forEach((order) => {
          const index = combinedOrders.findIndex(
            (item) => item.id === order.id
          );
          if (index !== -1) {
            combinedOrders[index].count += order.count;
          } else {
            combinedOrders.push(order);
          }
        });

        const updatedTotalPrice = existingPrice + totalPrice;

        const { data, error } = await supabase
          .from("activeOrder")
          .update({
            totalOrder: combinedOrders, //bura düzeltilecek
            totalPrice: updatedTotalPrice,
          })
          .eq("id", orderId)
          .select();
        if (error) {
          //console.log("Sipariş güncelleme hatası", error);
        } else {
          //console.log(data);
        }
      }
    }

    const { data, error } = await supabase.from("notification").insert({
      message: `Yeni sipariş verildi - Masa: ${table_name}`,
    });

    if (error) {
      console.log("Bildirim eklenemedi:", error);
    }

    localStorage.clear();
    window.location.reload();
  };
  return (
    <>
      <div className="flex justify-center items-center pt-4 ">
        <img
          src="/logo.png" // Logo görüntüsünün yolunu buraya ekleyin
          alt="Logo"
          className="w-16 h-16"
        />
      </div>
      <div className={classes.content}>
        {items.map((item) => (
          <MenuItem
            key={Math.random()}
            mealType={item.name}
            mealList={item.mealList}
          />
        ))}
      </div>
    </>
  );
};

export default HomePage;
