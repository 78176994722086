import React from "react";
import CafeLogo from "../logo-removebg.png"; // Logonuzun yolu
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        {/* Cafe Logosu */}
        <img src={CafeLogo} alt="Cafe Logo" className="mx-auto mb-4" />

        {/* Karşılama Mesajı */}
        <h1 className="text-4xl font-bold mb-4 p-8">Hoş Geldiniz!</h1>

        {/*<p className="text-lg mb-4 text-cyan-50 p-2">
          Sipariş vermek için masadaki QR kodunu okutun.
        </p>*/}

        <button
          onClick={() => navigate("/menu")}
          className="bg-amber-600 hover:bg-amber-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
        >
          Menü Sayfasına Git {"=>"}
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
