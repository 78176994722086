import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Admin from "./pages/adminPage/admin";
import Management from "./pages/adminPage/management";
import TableSettings from "./pages/adminPage/tableSettings";
import CategorySettings from "./pages/adminPage/categorySettings";
import MenuSettings from "./pages/adminPage/menuSettings";
import History from "./pages/adminPage/history";
import NotFoundPage from "./pages/NotFoundPage";
import PrivateRoute from "./components/adminComponents/PrivateRoute";

const router = createBrowserRouter([
  {
    path: "/:table_name",
    element: <HomePage />,
  },
  {
    path: "/home/:table_name",
    element: <HomePage />,
  },
  {
    path: "/menu",
    element: <HomePage />,
  },
  {
    path: "/21232f297a57a5a743894a0e4a801fc3",
    element: <Admin />,
  },
  {
    path: "/21232f297a57a5a743894a0e4a801fc3/management",
    element: <PrivateRoute element={<Management />} />,
  },
  {
    path: "/21232f297a57a5a743894a0e4a801fc3/tableSettings",
    element: <PrivateRoute element={<TableSettings />} />, // PrivateRoute kullanımı
  },
  {
    path: "/21232f297a57a5a743894a0e4a801fc3/categorySettings",
    element: <PrivateRoute element={<CategorySettings />} />, // PrivateRoute kullanımı
  },
  {
    path: "/21232f297a57a5a743894a0e4a801fc3/menuSettings",
    element: <PrivateRoute element={<MenuSettings />} />, // PrivateRoute kullanımı
  },
  {
    path: "/21232f297a57a5a743894a0e4a801fc3/history",
    element: <PrivateRoute element={<History />} />, // PrivateRoute kullanımı
  },
  {
    path: "*", // Her şeyi yakalayan rota
    element: <NotFoundPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
