import React, { useState, useEffect } from "react";
import AdminHeader from "./adminHeader";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DoneIcon from "@mui/icons-material/Done";
import jsPDF from "jspdf";
import supabase from "../../config/supabaseClient";

const Management = () => {
  //çalışanlar
  const [tables, setTables] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [isOccupied, setIsOccupied] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [orderQuantity, setOrderQuantity] = useState(1);
  //çalışmayanlar
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedTableOrders, setSelectedTableOrders] = useState([]);

  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [selectedTransferTable, setSelectedTransferTable] = useState("");

  const [searchTerm, setSearchTerm] = useState("");

  /*
  useEffect(() => {
    // Mevcut masaları, siparişleri ve menü öğelerini //localStorage'dan çekme
    const savedTables = JSON.parse(//localStorage.getItem("tables")) || [];
    const savedOrders = JSON.parse(//localStorage.getItem("orders")) || {};
    const savedMenuItems = JSON.parse(//localStorage.getItem("menus")) || [];
    setTables(savedTables);
    setOrderData(savedOrders);
    setMenuItems(savedMenuItems);
  }, []); */

  useEffect(() => {
    const fetchTables = async () => {
      const { data, error } = await supabase.from("tables").select();

      if (error || !data) {
        console.log("Masa bilgileri alınamadı.");
        return;
      } else {
        setTables(data);
        //console.log("masalar: ", data);
      }
    };

    const fetchItems = async () => {
      const { data, error } = await supabase.from("items").select();

      if (error || !data || data.length === 0) {
        console.log("Menü öğeleri alınamadı.");
        return;
      } else {
        setMenuItems(data);
        //console.log("menuItems: ", data);
      }
    };

    const fetchOrders = async () => {
      const { data, error } = await supabase.from("activeOrder").select();

      if (error || !data || data.length === 0) {
        console.log("Siparişler alınamadı.");
        return;
      } else {
        //console.log("siparişler: ", data);
        setOrderData(data);
      }
    };
    fetchTables();
    fetchItems();
    fetchOrders();
  }, []);

  const updateOrderData = (tableId, tableOrder) => {
    const updatedOrders = [...orderData];
    updatedOrders.forEach((order) => {
      if (order.table_id === tableId) {
        order.totalOrder = tableOrder;
      }
    });

    setOrderData(updatedOrders);
  };
  const handleTableClick = (table) => {
    setSelectedTable(table);
    setIsOccupied(table.hasGuest);

    const tableOrders = orderData.filter(
      (order) => order.table_id === table.id
    );
    setSelectedTableOrders(tableOrders);
  };

  const handleSwitchChange = () => {
    if (isOccupied && orderData[selectedTable.id]?.length > 0) {
      alert("Hesap ödenmediği sürece masa boş olarak işaretlenemez.");
      return;
    }
    const updatedTables = tables.map((table) =>
      table.id === selectedTable.id
        ? { ...table, hasGuest: !isOccupied }
        : table
    );
    setTables(updatedTables);
    //localStorage.setItem("tables", JSON.stringify(updatedTables));
    setIsOccupied(!isOccupied);
  };

  const handleOrderClick = async (tableId, orderIndex) => {
    const updatedOrders = { ...selectedTableOrders };
    updatedOrders[0].totalOrder[orderIndex].isNew = true;
    setSelectedTableOrders(updatedOrders);

    //updatedOrders[tableId][orderIndex].isNew = false;

    //setOrderData(updatedOrders);
    //localStorage.setItem("orders", JSON.stringify(updatedOrders));
  };

  const handleOrderDelete = async (tableId, orderIndex) => {
    const order = selectedTableOrders[0].totalOrder[orderIndex];

    if (order.count > 1) {
      const deleteCount = prompt(
        `${order.name} siparişinden kaç adet silmek istersiniz? (1-${order.count})`,
        1
      );

      if (
        !deleteCount ||
        isNaN(deleteCount) ||
        deleteCount < 1 ||
        deleteCount > order.count
      ) {
        alert("Geçersiz adet girdiniz.");
        return;
      }

      const confirmDelete = window.confirm(
        `${order.name} siparişinden ${deleteCount} adet silmek istediğinizden emin misiniz?`
      );

      if (!confirmDelete) {
        return;
      }

      // Adetten daha fazla değilse, sayıyı güncelle
      if (deleteCount < order.count) {
        let updatedOrders = { ...selectedTableOrders };
        updatedOrders[0].totalOrder[orderIndex].count -= deleteCount;
        setSelectedTableOrders(updatedOrders);

        const { data, error } = await supabase
          .from("activeOrder")
          .update({
            totalOrder: updatedOrders[0].totalOrder,
            totalPrice: calculateTotal(),
          })
          .eq("id", selectedTableOrders[0].id)
          .select()
          .single();

        if (!data || error) {
          alert("Sipariş silinemedi.");
          return;
        } else {
          updateOrderData(tableId);
          window.location.reload();
        }
        return;
      }
    }

    const confirmDeleteAll = window.confirm(
      `${order.name} x ${order.count} siparişini tamamen silmek istediğinizden emin misiniz?`
    );

    if (!confirmDeleteAll) {
      return;
    }
    const deleteCount = prompt(
      `${order.name} siparişinden kaç adet silmek istersiniz? (1-${order.count})`,
      1
    );

    if (
      !deleteCount ||
      isNaN(deleteCount) ||
      deleteCount < 1 ||
      deleteCount > order.count
    ) {
      alert("Geçersiz adet girdiniz.");
      return;
    }

    let updatedOrders = { ...selectedTableOrders };
    updatedOrders[0].totalOrder = updatedOrders[0].totalOrder.filter(
      (order, index) => index !== orderIndex
    );
    setSelectedTableOrders(updatedOrders);

    const { data, error } = await supabase
      .from("activeOrder")
      .update({
        totalOrder: updatedOrders[0].totalOrder,
        totalPrice: calculateTotal(),
      })
      .eq("id", selectedTableOrders[0].id)
      .select()
      .single();

    if (!data || error) {
      alert("Sipariş silinemedi.");
      return;
    } else {
      if (selectedTableOrders[0].totalOrder.length === 0) {
        const { data, error } = await supabase
          .from("tables")
          .update({ hasGuest: false })
          .eq("id", selectedTable.id)
          .select();

        if (error || !data) {
          alert("Masa güncellenemedi");
          return;
        }
        let updatedTables = [...tables];
        updatedTables.forEach((table) => {
          if (table.id === selectedTable.id) {
            table.hasGuest = false;
          }
        });
        setTables(updatedTables);
      }
      updateOrderData(tableId);
      window.location.reload();
    }
  };

  const handleOrderDelivered = async (tableId, orderIndex) => {
    // selectedTableOrders listesini kopyalayıp üzerinde işlem yapıyoruz
    let updatedOrders = [...selectedTableOrders];
    // Hedef tabloyu bulup, istenen siparişin teslim edildiğini işaretle
    updatedOrders[0].totalOrder[orderIndex].isDelivered = true;
    setSelectedTableOrders(updatedOrders);

    const { data, error } = await supabase
      .from("activeOrder")
      .update({ totalOrder: updatedOrders[0].totalOrder })
      .eq("id", selectedTableOrders[0].id)
      .select();

    if (!data || error) {
      alert("error", error);
      console.log(error); // Hata durumunda error'u loglayalım
      return;
    }
  };

  const handleAddOrder = async () => {
    if (!selectedMenuItem) {
      alert("Lütfen bir sipariş seçin.");
      return;
    }
    const selectedMenuItemDetails = menuItems.find(
      (item) => item.id === parseInt(selectedMenuItem)
    );
    const newOrder = {
      id: selectedMenuItemDetails.id,
      name: selectedMenuItemDetails.item_name,
      price: selectedMenuItemDetails.price,
      count: orderQuantity,
      isNew: false,
      isDelivered: false,
    };

    if (selectedMenuItemDetails.count - newOrder.count < 0) {
      alert("Stokta yeterli ürün yok");
      return;
    } else {
      // eğer masada daha önce sipariş yoksa
      if (
        selectedTableOrders.length === 0 ||
        selectedTableOrders[0].totalOrder.length === 0
      ) {
        //stok güncellenecek

        const { data: tableData, error: tableError } = await supabase
          .from("tables")
          .update({ hasGuest: true })
          .eq("id", selectedTable.id)
          .select();

        if (tableError || !tableData) {
          alert("Masa güncellenemedi");
          return;
        }
        let updatedTables = [...tables];
        updatedTables.forEach((table) => {
          if (table.id === selectedTable.id) {
            table.hasGuest = true;
          }
        });
        setTables(updatedTables);
        const { data, error } = await supabase
          .from("activeOrder")
          .insert([
            {
              table_id: selectedTable.id,
              totalOrder: [newOrder],
              totalPrice: newOrder.price * newOrder.count,
            },
          ])
          .select();

        if (data) {
          setSelectedTableOrders(data);
          let updatedOrders = [...orderData];
          updatedOrders.push(data[0]);
          setOrderData(updatedOrders);
        } else {
          console.log("error: ", error);
        }
      } else {
        const { data: updatedItem, error: updateError } = await supabase
          .from("items")
          .update({ stock: selectedMenuItemDetails.count - newOrder.count })
          .eq("id", selectedMenuItemDetails.id)
          .select();

        if (updateError || !updatedItem) {
          alert("Stok güncellenemedi");
          console.log("updateError: ", updateError);
          return;
        } else {
          let updatedOrders = { ...selectedTableOrders };
          let hasCountain = false;
          updatedOrders[0].totalOrder.forEach((order) => {
            if (order.name === newOrder.name) {
              order.count += newOrder.count;
              hasCountain = true;
              order.isDelivered = false;
            }
          });
          if (!hasCountain) {
            updatedOrders[0].totalOrder.push(newOrder);
          }

          const { data, error } = await supabase
            .from("activeOrder")
            .update({
              totalOrder: updatedOrders[0].totalOrder,
              totalPrice: calculateTotal(),
            })
            .eq("id", selectedTableOrders[0].id)
            .select();

          if (!data || error) {
            alert("Sipariş eklenemedi.");
            return;
          } else {
            setSelectedTableOrders(data);
            let lastOrders = [...orderData];
            lastOrders.forEach((order) => {
              if (order.table_id === selectedTable.id) {
                order.totalOrder = updatedOrders[0].totalOrder;
              }
            });
            setOrderData(lastOrders);
          }
        }
      }
    }

    //setOrderData(updatedOrders);
    //localStorage.setItem("orders", JSON.stringify(updatedOrders));
    setOrderQuantity(1); // Adeti sıfırla
  };

  const handleTransferTable = async () => {
    if (!selectedTransferTable || selectedTransferTable === selectedTable.id) {
      alert("Lütfen farklı bir masa seçin.");
      return;
    }

    const updatedOrders = { ...selectedTableOrders[0] };
    updatedOrders.table_id = selectedTransferTable;
    //delete updatedOrders[selectedTable.id];

    const { data, error } = await supabase
      .from("tables")
      .update({ hasGuest: false })
      .eq("id", selectedTable.id)
      .select();

    if (error || !data) {
      alert("Masa aktarılamadı.");
      return;
    } else {
      const { data: table2, error: table2error } = await supabase
        .from("tables")
        .update({ hasGuest: true })
        .eq("id", selectedTransferTable)
        .select();

      if (table2) {
        const { data, error } = await supabase
          .from("activeOrder")
          .update({ table_id: selectedTransferTable })
          .eq("id", selectedTableOrders[0].id)
          .select();

        if (data) {
          //console.log("masa aktarıldı", data);

          let updatedTableOrders = [...orderData];
          updatedTableOrders.forEach((order) => {
            if (order.table_id === parseInt(selectedTable.id)) {
              order.table_id = parseInt(selectedTransferTable);
            }
          });

          let newTables = [...tables];
          let new_table = "";

          //console.log("transfer edilecek masa", selectedTransferTable);
          //console.log("şimdiki  masa", selectedTable);
          tables.forEach((table) => {
            //console.log("masa: ", table);
            //console.log("transfer edilecek masa", selectedTransferTable);
            if (table.id === selectedTable.id) {
              table.hasGuest = false;
            }
            if (table.id === parseInt(selectedTransferTable)) {
              table.hasGuest = true;
              new_table = table.table_name;
            }
          });

          setSelectedTableOrders([updatedOrders]);
          //console.log("siparişler" , orderData)
          //console.log("updatedTableOrders: ", updatedTableOrders);
          setOrderData(updatedTableOrders);
          setTables(newTables);
          alert(
            `Masa ${selectedTable.table_name}, ${new_table} masaya aktarıldı.`
          );
          setSelectedTable(null);
        } else {
          console.log("error: ", error);
        }
      } else {
        console.log("table2error: ", table2error);
      }
    }

    //localStorage.setItem("orders", JSON.stringify(updatedOrders));
  };
  const replaceTurkishCharacters = (text) => {
    const turkishChars = {
      ç: "c",
      Ç: "C",
      ğ: "g",
      Ğ: "G",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ş: "s",
      Ş: "S",
      ü: "u",
      Ü: "U",
    };

    return text.replace(
      /ç|Ç|ğ|Ğ|ı|İ|ö|Ö|ş|Ş|ü|Ü/g,
      (char) => turkishChars[char]
    );
  };

  const handlePrintReceipt = () => {
    const orders = selectedTableOrders[0].totalOrder;
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [58, 100 + orders.length * 10], // Dinamik yükseklik
    });

    doc.setFontSize(12);
    doc.text("Twin Coffee", 29, 10, { align: "center" });
    doc.setFontSize(10);
    doc.text(
      `Masa: ${replaceTurkishCharacters(selectedTable.table_name)}`,
      29,
      20,
      { align: "center" }
    );
    doc.text(`Tarih: ${new Date().toLocaleString()}`, 29, 26, {
      align: "center",
    });

    let yPosition = 36;
    let total = 0;

    orders.forEach((order) => {
      const orderTotal = order.price * order.count;
      const orderName = replaceTurkishCharacters(order.name);
      doc.text(
        `${orderName} x ${order.count} = ${orderTotal} TL`,
        4,
        yPosition
      );
      total += orderTotal;
      yPosition += 10;
    });

    doc.setFontSize(12);
    doc.text(`Toplam:${total} TL`, 29, yPosition, { align: "center" });

    doc.output("dataurlnewwindow");
  };

  const handlePayment = async () => {
    if (window.confirm("Ödeme alındı mı?")) {
      //setOrderData(updatedOrders);
      //localStorage.setItem("orders", JSON.stringify(updatedOrders));
      const updatedTables = tables.map((table) =>
        table.id === selectedTable.id ? { ...table, hasGuest: false } : table
      );

      const paymentMethod = prompt(
        "Ödemeyi hangi yöntemle yaptınız? (Örn: Kredi Kartı, Nakit, vb.)"
      );

      if (paymentMethod) {
        const { data: tableData, error: tableError } = await supabase
          .from("tables")
          .update({ hasGuest: false })
          .eq("id", selectedTable.id)
          .select()
          .single();

        if (tableError || !tableData) {
          alert("Masa ödeme alındı olarak işaretlenemedi.");
          return;
        } else {
          const tableId = tableData.id;

          const { data: orderDataS, error: errorData } = await supabase
            .from("activeOrder")
            .delete()
            .eq("id", selectedTableOrders[0].id)
            .select();

          if (errorData || !orderDataS) {
            alert("Siparişler silinemedi.");
            return;
          } else {
            const { data, error } = await supabase
              .from("orderHistory")
              .insert([
                {
                  table_id: tableId,
                  totalOrder: orderDataS[0].totalOrder,
                  totalPrice: orderDataS[0].totalPrice,
                  paymentMethod: paymentMethod,
                },
              ])
              .select();

            if (error || !data) {
              alert("Siparişler geçmişe aktarılamadı.");
              return;
            } else {
              setTables(updatedTables);
              const updatedOrders = orderData.filter(
                (order) =>
                  parseInt(order.id) !== parseInt(selectedTableOrders[0].id)
              );
              setOrderData(updatedOrders);
              setSelectedTableOrders([
                {
                  totalOrder: [],
                },
              ]);
            }
          }
        }
        //setTables(updatedTables);
        //localStorage.setItem("tables", JSON.stringify(updatedTables));
        //setSelectedTable(null);
        alert("Ödeme alındı ve masa boş olarak işaretlendi.");
      } else {
        // Kullanıcı hiçbir şey girmezse veya iptal ederse
        alert("Ödeme yöntemi belirtilmedi.");
      }
    }
  };

  const calculateTotal = () => {
    let total = 0;

    // Eğer selectedTableOrders tanımsızsa veya boşsa sıfır döndür
    if (!selectedTableOrders || selectedTableOrders.length === 0) {
      return total;
    }

    // Eğer totalOrder tanımsızsa veya boşsa sıfır döndür
    if (
      !selectedTableOrders[0].totalOrder ||
      selectedTableOrders[0].totalOrder.length === 0
    ) {
      return total;
    }

    // Siparişlerin toplamını hesapla
    selectedTableOrders[0].totalOrder.forEach((order) => {
      total += order.price * order.count;
    });

    return total;
  };

  return (
    <div className="Management">
      <AdminHeader />
      <div className="flex h-screen">
        {/* Sol kısım - Mevcut masalar */}
        <div className="w-1/3 p-4 bg-gray-100">
          <h2 className="text-2xl font-semibold mb-4">Mevcut Masalar</h2>
          <div className="grid grid-cols-1 gap-4">
            {tables && tables.length > 0 ? (
              tables.map((table) => (
                <div
                  key={table.id}
                  className={`p-4 bg-white rounded cursor-pointer flex justify-between items-center ${
                    table.hasGuest ? "bg-red-100" : "bg-green-100"
                  }`}
                  onClick={() => handleTableClick(table)}
                >
                  <span>{table.table_name}</span>
                  <span
                    className={`w-3 h-3 rounded-full ${
                      table.hasGuest ? "bg-red-500" : "bg-green-500"
                    }`}
                  ></span>
                </div>
              ))
            ) : (
              <p className="text-gray-500">Mevcut masa bulunamadı.</p>
            )}
          </div>
        </div>

        {/* Sağ kısım - Masa detayları */}
        <div className="w-2/3 p-4 bg-white border-l">
          {selectedTable ? (
            <>
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">
                  Masa: {selectedTable.table_name}
                </h2>
                <Switch
                  checked={isOccupied}
                  onChange={handleSwitchChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <div className="mb-4">
                <h3 className="text-xl font-semibold">Siparişler</h3>
                {selectedTableOrders &&
                selectedTableOrders.length > 0 &&
                selectedTableOrders[0].totalOrder ? (
                  selectedTableOrders[0].totalOrder.map((order, index) => (
                    <div
                      key={order.id}
                      className={`p-2 mb-2 rounded flex justify-between items-center ${
                        !order.isDelivered ? "bg-yellow-100" : "bg-gray-100"
                      }`}
                    >
                      <span>
                        {order.name} x {order.count} ={" "}
                        {order.price * order.count}₺
                      </span>
                      <div className="flex items-center">
                        {!order.isDelivered && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOrderDelivered(selectedTable.id, index);
                            }}
                            className="bg-green-500 text-white px-2 py-1 rounded mr-2"
                          >
                            <DoneIcon />
                          </button>
                        )}
                        {!order.isDelivered && (
                          <DeleteIcon
                            className="text-red-500 cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOrderDelete(selectedTable.id, index);
                            }}
                          />
                        )}
                        {order.isDelivered && <p>Sipariş Teslim Edildi</p>}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">Bu masaya ait sipariş yok.</p>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Sipariş Seç</label>
                <input
                  type="text"
                  placeholder="Sipariş ara..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full p-2 mt-2 border rounded mb-2"
                />
                <select
                  value={selectedMenuItem}
                  onChange={(e) => {
                    setSelectedMenuItem(e.target.value);
                    setSearchTerm("");
                  }}
                  className="w-full p-2 mt-2 border rounded"
                >
                  <option value="">Sipariş Seçin</option>
                  {menuItems
                    .filter((item) =>
                      item.item_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.item_name} - {item.price}₺
                      </option>
                    ))}
                </select>
                <div className="mt-4">
                  <label className="block text-gray-700">Adet Gir</label>
                  <input
                    type="number"
                    value={orderQuantity}
                    onChange={(e) => setOrderQuantity(parseInt(e.target.value))}
                    className="w-full p-2 mt-2 border rounded"
                    min="1"
                  />
                </div>
                <button
                  onClick={handleAddOrder}
                  className="bg-blue-500 text-white py-2 px-4 rounded mt-4"
                >
                  Sipariş Ekle
                </button>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Masaya Aktar</label>
                <select
                  value={selectedTransferTable}
                  onChange={(e) => setSelectedTransferTable(e.target.value)}
                  className="w-full p-2 mt-2 border rounded"
                >
                  <option value="">Masa Seçin</option>
                  {tables
                    .filter(
                      (table) =>
                        table.id !== selectedTable.id && !table.hasGuest
                    )
                    .map((table) => (
                      <option key={table.id} value={table.id}>
                        {table.table_name}
                      </option>
                    ))}
                </select>
                <button
                  onClick={handleTransferTable}
                  className="bg-yellow-500 text-white py-2 px-4 rounded mt-4"
                >
                  Aktar
                </button>
              </div>
              <div className="mt-4">
                <h3 className="text-xl font-semibold">
                  Toplam Tutar: {calculateTotal()}₺
                </h3>
              </div>
              <div className="flex justify-between mt-4">
                <button
                  onClick={handlePrintReceipt}
                  className="bg-gray-500 text-white py-2 px-4 rounded flex items-center"
                >
                  <PictureAsPdfIcon className="mr-2" />
                  Fiş Ver
                </button>
                <button
                  onClick={handlePayment}
                  className="bg-green-500 text-white py-2 px-4 rounded"
                >
                  Ödendi
                </button>
              </div>
            </>
          ) : (
            <p className="text-gray-700 flex justify-center items-center h-full">
              Bir masa seçin.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Management;
