import { green } from "@mui/material/colors";
import style from "./clickedItem.module.css";
import { useState } from "react";
import { useEffect } from "react";

const ClickedItem = (props) => {
  const [price, setPrice] = useState(0);
  const [number, setNumber] = useState(0);

  const findPriceNumber = () => {
    const local = localStorage.getItem(props.name);
    if (local) {
      const selectedItem = JSON.parse(local);
      setPrice(selectedItem.price);
      setNumber(selectedItem.count);
    }
  };
  const addMeal = () => {
    //console.log(price);
    setNumber((prevNumber) => prevNumber + 1);
    setPrice(props.price * (number + 1));
    //console.log("adet: ", number + 1);
    //console.log("fiyat: ", props.price * (number + 1));
  };

  const reduceMeal = () => {
    if (number >= 1) {
      setNumber((prevNumber) => prevNumber - 1);
      setPrice(props.price * (number - 1));
    }
  };

  useEffect(() => {
    findPriceNumber();
  }, []);
  const addCart = () => {
    //buraya aynı zamanda seçilen ürünlerin listeye eklenmesi de eklenecek

    localStorage.setItem(
      props.name,
      JSON.stringify({
        name: props.name,
        price: price,
        count: number,
      })
    );
    if (number === 0) {
      localStorage.removeItem(props.name);
    }
    props.onAdd({
      id: props.id,
      name: props.name,
      price: props.price,
      count: number,
    });
  };

  const closeCart = () => {
    props.onClose();
  };
  return (
    <div className={style.card}>
      <div
        onClick={closeCart}
        style={{
          color: "white",
          position: "absolute",
          top: "-0.5em",
          right: "-0.5rem",
          cursor: "pointer",
          fontSize: "2.5rem",
          fontWeight: "bold",
        }}
      >
        X
      </div>{" "}
      <div className={style.details}>
        <img src={props.img} alt={props.name} width={"130px"}></img>
        <p style={{ fontWeight: "bold" }}>
          {props.name} / {props.price} ₺
        </p>

        <p style={{ marginTop: "1rem", color: "#fff" }}>{props.description}</p>
      </div>
    </div>
  );
};

export default ClickedItem;
